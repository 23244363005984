<template>
  <div class="col-md-12">
    <div class="card card-container">
      <form @submit.prevent="IntegratedOfferAssignIngredient">
        <label for="integrated_offer_id">select an integrated offer</label>
        <select v-model="integrated_offer_id" name="integrated_offer_id" class="form-select" v-if="integratedOffers">
          <option v-for="(k, ingtegratedOffer) in integratedOffers.data" v-bind:key="ingtegratedOffer" :value="k.id">{{ k.name }}</option>
        </select>
        <label for="ingredient_id">select a ingredient</label>
        <select v-model="ingredient_id" class="form-select" v-if="ingredients">
            <option  v-for="(k, ingredient) in ingredients.data" v-bind:key="ingredient" :value="k.id">{{ k.name }}</option>
        </select>
        <div class="mb-3">
          <label for="amount" class="form-label">ingredient amount</label>
          <input type="number" min="0" step="any" class="form-control" id="amount" v-model="amount" required="required" ref="amount">
        </div>
        <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              assign ingredient to integrated offer
            </button>
          </div>
     </form>

    <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
    </div>

  </div>
</template>

<script>
import IntegratedOfferService from "../services/integrated-offer.service";
import IngredientsService from "../services/ingredients.service";

export default {
  name: "Assign ingredient to integrated offer",
  components: {
  },
  methods: {
    IntegratedOfferAssignIngredient() {
      this.formData = {
        'ingredient_id': this.ingredient_id,
        'integrated_offer_id': this.integrated_offer_id,
        'amount': this.amount,
      };
      IntegratedOfferService.IntegratedOfferAssignIngredient(this.formData).then(
          (response) => {
            this.message = response.data.message;
            this.successful = true;
            this.loading = false;
            this.ingredient_id = null;
            this.integrated_offer_id = null;
            this.amount = null;
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  data() {
    return {
      successful: false,
      loading: false,
      message: "",

      ingredient_id: "",
      integrated_offer_id: "",
      formData: {},
      ingredients: "",
      integratedOffers: "",
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }

    IngredientsService.getIngredients().then(
      (response) => {
        this.ingredients = response.data;
        console.log(this.ingredients);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

    IntegratedOfferService.getIntegratedOffers().then(
      (response) => {
        this.integratedOffers = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};

</script>

<style scoped>
</style>

